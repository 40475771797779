<template>
  <div
    v-if="handleSubmit"
    class="d-flex my-2 justify-content-center align-items-center align-content-center"
  >
    <div class="loader"></div>
    <span class="ml-1">Envoi en cours...</span>
  </div>

  <form v-if="!handleSubmit && !isAuthenticated" @submit.prevent="" class="comment-form form flex-grid">
    <div class="flex-5 flex-s-12">
      <div class="mb-05 d-flex align-items-center align-content-center justify-content-start">
        <i class="fas fa-fw fa-user"></i><span class="required mr-05">*</span>
        <input :class="userNameClasses" id="user_name" v-model="user_name" placeholder="Nom*" type="text">
      </div>

      <div class="mb-05 d-flex align-items-center align-content-center justify-content-start">
        <i class="fas fa-fw fa-envelope"></i><span class="required mr-05">*</span>
        <input :class="userEmailClasses" id="user_email" v-model="user_email" placeholder="Email*" type="email">
      </div>

      <div class="mb-05 d-flex align-items-center align-content-center justify-content-start">
        <i class="fas fa-fw fa-globe"></i><span class="required mr-05 hidden">*</span>
        <input :class="userUrlClasses" id="user_url" v-model="user_url" placeholder="Site web" type="text">
      </div>
    </div>

    <div class="flex-7 flex-s-12 d-flex">
      <i class="fas fa-feather mt-1"></i><span class="required mr-05 mt-1">*</span>
      <textarea :class="userCommentClasses" id="message" v-model="user_comment" class="comment" placeholder="Votre message*"></textarea>
    </div>

    <div class="flex-12 text-right">
      <div :id="hcaptcha_html_id" :data-sitekey="hcaptchaSitekey" :class="hcaptchaClasses"></div>

      <div class="d-flex justify-content-end mb-1">
        <input :id="privacy_policy_accept_html_id" v-model="user_privacy_policy_accept" class="checkbox-input"
               name="privacy_policy_accept" type="checkbox">
        <label :class="userPrivacyPolicyAcceptClasses" :for="privacy_policy_accept_html_id">
          <span class="noselect">J'accepte la politique de confidentialité</span><span class="noselect required">*</span></label>
      </div>

      <button class="btn btn-primary btn-small" @click.prevent="submitForm">Publier</button>
    </div>
  </form>

  <form v-if="!handleSubmit && isAuthenticated" @submit.prevent="" class="comment-form form flex-grid">
    <div class="flex-12 flex-s-12 text-left d-flex justify-content-start align-items-center align-content-center">
      <i class="fas fa-fw fa-user mr-1"></i>
      <span class="mr-05">{{ authenticatedUserDisplayName }}</span>
      <span class="text-primary">(connecté)</span>
    </div>
    <div class="flex-12 flex-s-12 d-flex">
      <i class="fas fa-feather mt-1"></i><span class="required mr-05 mt-1">*</span>
      <textarea :class="userCommentClasses" id="message" v-model="user_comment" class="comment" placeholder="Votre message*"></textarea>
    </div>

    <div class="flex-12 text-right">
      <div :id="hcaptcha_html_id" :data-sitekey="hcaptchaSitekey" :class="hcaptchaClasses"></div>

      <button class="btn btn-primary btn-small mt-1" @click.prevent="submitForm">Publier</button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'CommentForm',
  props: {
    authenticatedUserDisplayName: {
      type: String,
      default: ''
    },
    csrftoken: {
      type: String,
      default: null
    },
    hcaptchaSitekey: {
      type: String,
      default: ''
    },
    pageId: {
      type: Number,
      default: null
    },
    commentParentId: {
      type: Number,
      default: null
    },
  },
  data: () => {
    return {
      hcaptchaWidgetId: null,
      user_name: null,
      user_email: null,
      user_url: null,
      user_comment: null,
      user_privacy_policy_accept: false,
      hcaptcha_html_id: 'hcaptcha_comment_form',
      privacy_policy_accept_html_id: 'privacy_policy_accept',
      hcaptcha_response: null,
      errors: null,
      handleSubmit: false
    }
  },
  created() {
    this.resetFormErrors();
  },
  beforeMount() {
    if(this.commentParentId !== null) {
      this.hcaptcha_html_id = `hcaptcha_comment_form_${this.commentParentId}`;
      this.privacy_policy_accept_html_id = `privacy_policy_accept_${this.commentParentId}`;
    }
  },
  mounted() {
    this.renderHcaptcha();
  },
  computed: {
    isAuthenticated() {
      return this.authenticatedUserDisplayName !== '';
    },
    userNameClasses() {
      let classes = [];
      if(this.errors['user_name'] != null) {
        classes.push('errors');
      }
      return classes;
    },
    userEmailClasses() {
      let classes = [];
      if(this.errors['user_email'] != null) {
        classes.push('errors');
      }
      return classes;
    },
    userUrlClasses() {
      let classes = [];
      if(this.errors['user_url'] != null) {
        classes.push('errors');
      }
      return classes;
    },
    userCommentClasses() {
      let classes = [];
      if(this.errors['user_comment'] != null) {
        classes.push('errors');
      }
      return classes;
    },
    userPrivacyPolicyAcceptClasses() {
      let classes = [
          'checkbox-label'
      ];
      if(this.errors['user_privacy_policy_accept'] != null) {
        classes.push('errors');
      }
      return classes;
    },
    hcaptchaClasses() {
      let classes = [
          'h-captcha',
          'mb-05'
      ];
      if(this.errors['hcaptcha'] != null) {
        classes.push('errors');
      }
      return classes;
    },
  },
  methods: {
    renderHcaptcha() {
      this.hcaptchaWidgetId = window.hcaptcha.render(`${this.hcaptcha_html_id}`, {
        sitekey: this.hcaptchaSitekey
      });
    },
    validateFormAnonymousUser(isValid) {
      /* extra validations for anonymous user*/

      /* user name validation */
      if((this.user_name || "").length < 2) {
        this.errors.user_name = 'Le nom doit faire au moins 2 caractères.'
        isValid = false;
      }

      /* email validation */
      if((this.user_email || "").length < 6) {
        this.errors.user_email = 'Adresse email non valide';
        isValid = false;
      }

      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(this.user_email !== "" && !emailRegex.test(this.user_email)) {
        this.errors.user_email = 'Adresse email non valide';
        isValid = false;
      }

      /* url validation */
      if(this.user_url !== null && this.user_url !== "") {
        const url_regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

        if(!url_regex.test(this.user_url)) {
          this.errors.user_url = 'URL non valide';
          isValid = false
        }
      }

      /* privacy policy validation */
      if(!this.user_privacy_policy_accept) {
        this.errors.user_privacy_policy_accept = 'Vous devez accepter la politique de confidentialité';
        isValid = false;
      }

      return isValid;
    },
    validateForm() {
      let isValid = true;

      /* hcaptcha validation */
      if(this.hcaptcha_response === null || this.hcaptcha_response === "") {
        this.errors.hcaptcha = 'Veuillez compléter le captcha.'
        isValid = false;
      }

      /* user comment validation */
      if((this.user_comment || "").length < 1) {
        this.errors.user_comment = 'Votre message doit comporter au moins 1 caractère.'
        isValid = false;
      }

      if(!this.isAuthenticated) {
        isValid = this.validateFormAnonymousUser(isValid);
      }

      return isValid;
    },
    resetFormErrors() {
      this.errors = {
        'user_name': null,
        'user_email': null,
        'user_url': null,
        'user_comment': null,
        'user_privacy_policy_accept': null,
        'hcaptcha': null
      };
    },
    resetForm() {
      this.user_name = null;
      this.user_email = null;
      this.user_url = null;
      this.user_comment = null;
      this.user_privacy_policy_accept = null;
      this.hcaptcha_response = null;
    },
    submitForm() {
      this.resetFormErrors();
      if(this.handleSubmit) {
        return;
      }
      this.hcaptcha_response = window.hcaptcha.getResponse(this.hcaptchaWidgetId);
      if(!this.validateForm()) {
        return;
      }

      this.handleSubmit = true;
      const _this = this;

      let data = {
        "content_type": "blog.blogpage",
        "object_pk": this.pageId,
        "name": this.user_name,
        "email": this.user_email,
        "comment": this.user_comment,
        "privacy_policy_accept": this.user_privacy_policy_accept,
        "hcaptcha_response": this.hcaptcha_response,
        "followup": false,
      };

      if (this.commentParentId) {
        data['reply_to'] = this.commentParentId;
      }
      if (this.user_url !== null && this.user_url !== "") {
        data['url'] = this.user_url;
      }

      /* remove unnecessary fields for authenticated user */
      if(this.isAuthenticated) {
        data['email'] = '';
        data['name'] = '';
        data['privacy_policy_accept'] = true;
        delete data['url'];
      }

      fetch('/api/v1/comments/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRFToken': this.csrftoken
        },
        body: JSON.stringify(data)
      }).then(res => {
        if(!(res.status >= 200 && res.status < 300)) {
          _this.handleSubmit = false;
          _this.$nextTick(() => {
            _this.renderHcaptcha();
          });
        }
        return res.json();
      }).then((res) => {
        if(res['id']) {
          _this.$emit('hideCommentForm', _this.commentParentId);
          _this.resetForm();
        }
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        _this.$emit('fetchComments');
        _this.handleSubmit = false;
        _this.hcaptcha_response = null;
      })
    }
  }
}
</script>
