import { createApp } from 'vue'
import CommentsContainer from './components/comments/CommentsContainer.vue'

if(document.getElementById('comments_container')) {
  const comments_container = document.getElementById('comments_container');

  const vm = createApp(CommentsContainer, {
    appName: comments_container.getAttribute('data-app-name'),
    pageId: parseInt(comments_container.getAttribute('data-page-id'), 10),
    hcaptchaSitekey: comments_container.getAttribute('data-hcaptcha-sitekey'),
    csrftoken: comments_container.getAttribute('data-csrf-token'),
    authenticatedUserDisplayName: comments_container.getAttribute('data-display-name')
  });
  vm.mount('#comments_container');
}
