<template>

  <div class="comments-counter">
    <h3>{{ nbComments }} commentaires</h3>
  </div>

  <div v-if="loadingComments" class="d-flex justify-content-center align-items-center align-content-center">
    <div class="loader"></div>
    <span class="ml-1">Chargement en cours...</span>
  </div>

  <div v-if="!loadingComments" class="text-center my-4">
    <button v-if="!isCommentFormShowed" class="btn btn-primary" @click="showCommentForm">
      Ajouter un commentaire
    </button>
    <comment-form
      v-if="isCommentFormShowed"
      :hcaptchaSitekey="hcaptchaSitekey"
      :pageId="pageId"
      :csrftoken="csrftoken"
      :authenticatedUserDisplayName="authenticatedUserDisplayName"
      @hideCommentForm="hideCommentForm"
      @fetchComments="fetchComments"
    />
  </div>
  <comment-list
    v-if="!loadingComments"
    :csrftoken="csrftoken"
    :comments="comments"
    :authenticatedUserDisplayName="authenticatedUserDisplayName"
    :hcaptchaSitekey="hcaptchaSitekey"
    :pageId="pageId"
    @fetchComments="fetchComments"
  />

</template>

<script>
import CommentForm from "./CommentForm.vue";
import CommentList from "./CommentList";

export default {
  name: 'CommentsContainer',
  props: {
    pageId: {
      type: Number,
      default: null,
    },
    appName: {
      type: String,
      default: 'blog-blogpage'
    },
    authenticatedUserDisplayName: {
      type: String,
      default: null
    },
    hcaptchaSitekey: {
      type: String,
      default: null,
    },
    csrftoken: {
      type: String,
      default: null,
    }
  },
  data: () => {
    return {
      comments: [],
      nbComments: 0,
      isCommentFormShowed: false,
      loadingComments: true
    }
  },
  components: {
    'comment-form': CommentForm,
    'comment-list': CommentList
  },
  mounted() {
    this.fetchComments();
  },
  methods: {
    fetchComments() {
      fetch(`/api/v1/comments/list/${this.appName}/${this.pageId}/`)
      .then(res => res.json())
      .then((res) => {
        this.comments = res;
      }).finally(() => {
        this.loadingComments = false;
      });

      fetch(`/comments/api/${this.appName}/${this.pageId}/count/`)
      .then(res => res.json())
      .then((res) => {
        this.nbComments = res['count'];
      });
    },
    showCommentForm() {
      this.isCommentFormShowed = true;
    },
    hideCommentForm() {
      this.isCommentFormShowed = false;
    }
  }
}
</script>
