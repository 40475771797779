<template>
  <ul v-if="comments.length" class="comments">
    <!-- comment -->
    <li v-for="comment in comments" :key="`comment_${comment.id}`">
      <div class="comment">
        <!-- comment_avatar -->
        <div class="avatar-container">
          <img :alt="comment.user_name" :src="comment.user_avatar" class="avatar">
          <div
            v-if="comment.is_staff || comment.is_superuser"
            :class="{
            'tag': true,
            'superuser': comment.is_superuser,
            'staff': comment.is_staff,
          }"
          >
            {{ getRankName(comment) }}
          </div>
          <!-- comment_avatar -->
        </div>
        <div class="body-container">
          <div class="content">
            <div class="d-flex justify-content-start align-items-center align-content-center">
              <h4 class="comment-username mr-1">{{ comment.user_name }}</h4>
              <a v-if="comment.user_url" :href="comment.user_url" rel="external ugc nofollow"><i class="fas fa-globe"></i></a>
            </div>
            <p class="comment-date">{{ comment.submit_date }}</p>
            <p class="message">{{ comment.comment }}</p>
            <button v-if="!isCommentFormShowed[comment.id]" @click="showCommentForm(comment.id)" class="btn btn-primary btn-small">Répondre</button>
          </div>

          <!-- answers -->
          <ul v-if="comment.children.length" class="comment-answers">
            <li v-for="child_comment in comment.children" :key="`child_comment_${child_comment.id}`">
              <div class="comment">
                <!-- child_comment_avatar -->
                <div class="avatar-container">
                  <img :alt="child_comment.user_name" :src="child_comment.user_avatar" class="avatar">
                  <div
                    v-if="child_comment.is_staff || child_comment.is_superuser"
                    :class="{
                      'tag': true,
                      'superuser': child_comment.is_superuser,
                      'staff': child_comment.is_staff,
                    }"
                  >
                    {{ getRankName(child_comment) }}
                  </div>
                </div>
                <!-- /child_comment_avatar -->
                <div class="body-container">
                  <div class="content">
                    <h4 class="comment-username">{{ child_comment.user_name }}</h4>
                    <p class="comment-date">{{ child_comment.submit_date }}</p>
                    <p class="message">{{ child_comment.comment }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div :id="`comment_form_reply_to_${comment.id}_anchor`" class="hidden"></div>
          <comment-form
            v-if="isCommentFormShowed[comment.id]"
            :commentParentId="comment.id"
            :hcaptchaSitekey="hcaptchaSitekey"
            :pageId="pageId"
            :csrftoken="csrftoken"
            :authenticatedUserDisplayName="authenticatedUserDisplayName"
            @fetchComments="$emit('fetchComments')"
            @hideCommentForm="hideCommentForm"
          />
          <!-- /answers -->
        </div>
      </div>
    </li>
    <!-- /comment -->
  </ul>
</template>

<script>
import CommentForm from "./CommentForm";

export default {
  name: 'CommentList',
  props: {
    comments: {
      type: Array,
      default: () => []
    },
    authenticatedUserDisplayName: {
      type: String,
      default: null,
    },
    hcaptchaSitekey: {
      type: String,
      default: null,
    },
    csrftoken: {
      type: String,
      default: null,
    },
    pageId: {
      type: Number,
      default: null,
    },
  },
  data: () => {
    return {
      isCommentFormShowed: {},
    }
  },
  components: {
    'comment-form': CommentForm
  },
  methods: {
    showCommentForm(comment_id) {
      this.isCommentFormShowed[comment_id] = true;
      window.scroll({
        top: document.getElementById(`comment_form_reply_to_${comment_id}_anchor`).offsetTop - 150,
        behavior: 'smooth'
      });
    },
    hideCommentForm(comment_id) {
      if(comment_id) {
        this.isCommentFormShowed[comment_id] = false;
      }
    },
    getRankName(comment) {
      if(comment.is_superuser) {
        return "Admin"
      }
      if(comment.is_staff) {
        return "Staff"
      }
    }
  }
}
</script>
